import { NbMediaBreakpoint, } from '@nebular/theme';
import { CommonMockModule } from './@core/mock/common/common-mock.module';
export var NB_CORE_PROVIDERS = CommonMockModule.forRoot().providers.slice();
var mediaBreakpoints = [
    {
        name: 'xs',
        width: 0,
    },
    {
        name: 'sm',
        width: 320,
    },
    {
        name: 'md',
        width: 480,
    },
    {
        name: 'lg',
        width: 768,
    },
    {
        name: 'xl',
        width: 1024,
    },
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
