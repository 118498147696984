import { NavigationEnd, Router } from '@angular/router';
import { filter, map, shareReplay } from 'rxjs/operators';
import { NbSidebarService } from '@nebular/theme';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, sidebarService) {
        var _this = this;
        this.router = router;
        this.sidebarService = sidebarService;
        this.defaultLayout = {
            paddings: {
                paddingTop: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingTopUnit: 'px',
                paddingRightUnit: 'px',
                paddingBottomUnit: 'px',
                paddingLeftUnit: 'px'
            },
            header: true,
            sidebar: true
        };
        this.layout$ = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () {
            var route = _this.router.routerState.root;
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route.snapshot.data['layout'] || _this.defaultLayout;
        }), shareReplay());
        this.padding$ = this.layout$
            .pipe(map(function (layout) { return _this.getPaddingCssValue(layout.paddings); }));
        this.items = [
            {
                'title': 'Dashboard',
                'link': '/overview',
                'icon': 'book-open',
                'children': [
                    {
                        'title': 'Devices Overview',
                        'link': '/overview/devices-overview',
                        'children': null
                    },
                    {
                        'title': 'Real Time Data',
                        'link': '/overview/real-time-data',
                        'children': null
                    },
                    {
                        'title': 'Events',
                        'link': '/overview/events',
                        'children': null
                    },
                ]
            },
            {
                'title': 'Devices',
                'link': '/devices',
                'icon': 'smartphone-outline',
                'children': [
                    {
                        'title': 'Fleet',
                        'link': '/devices/devices-list',
                        'children': null
                    },
                    {
                        'title': 'Devices Assignment',
                        'link': '/devices/devices-assignment',
                        'children': null
                    },
                    {
                        'title': 'Device Details',
                        'link': '/devices/device-details',
                        'children': null
                    },
                ]
            },
            // {
            //   'title': 'Trips',
            //   'link': '/trips',
            //   'icon': 'map',
            //   'children': [
            //     {
            //       'title': 'Overview',
            //       'link': '/trips/trips-overview',
            //       'children': null
            //     },
            //     {
            //       'title': 'Upcoming Trip',
            //       'link': '/trips/schedule-trip',
            //       'children': null
            //     },
            //     {
            //       'title': 'Schedule Trip',
            //       'link': '/trips/trip-assignment',
            //       'children': null
            //     },
            //   ]
            // },
            // {
            //   'title': 'Maintenance',
            //   'link': '/maintenance',
            //   'icon': 'settings-2',
            //   'children': [
            //     {
            //       'title': 'Maintenance Planner',
            //       'link': '/maintenance/maintenance-planner',
            //       'children': null
            //     },
            //     {
            //       'title': 'History',
            //       'link': '/maintenance/history',
            //       'children': null
            //     }
            //   ]
            // },
            {
                'title': 'Analytics',
                'link': '/analytics',
                'icon': 'pie-chart',
                'children': null
            }
        ];
    }
    AppComponent.prototype.toggleSidebar = function () {
        this.sidebarService.toggle(true);
    };
    AppComponent.prototype.getPaddingCssValue = function (paddings) {
        return "" + paddings.paddingTop + paddings.paddingTopUnit + " " +
            ("" + paddings.paddingRight + paddings.paddingRightUnit + " ") +
            ("" + paddings.paddingBottom + paddings.paddingBottomUnit + " ") +
            ("" + paddings.paddingLeft + paddings.paddingLeftUnit);
    };
    return AppComponent;
}());
export { AppComponent };
