import { Routes } from '@angular/router';
var routes = [
    { path: 'overview', loadChildren: './dashboard/dashboard.module#DashboardModule' },
    { path: 'devices', loadChildren: './vehicles/vehicles.module#VehiclesModule' },
    { path: 'trips', loadChildren: './trips/trips.module#TripsModule' },
    { path: 'maintenance', loadChildren: './maintenance/maintenance.module#MaintenanceModule' },
    { path: 'analytics', loadChildren: './analytics/analytics.module#AnalyticsModule' },
    { path: '', redirectTo: 'overview', pathMatch: 'full' },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
