var DriversService = /** @class */ (function () {
    function DriversService() {
        this.data = [
            {
                name: 'John',
                surname: 'Doe'
            }, {
                name: 'Steve',
                surname: 'Garberg',
            }, {
                name: 'Alex',
                surname: 'Anderson'
            }, {
                name: 'George',
                surname: 'Williams'
            }
        ];
    }
    DriversService.prototype.getDrivers = function () {
        return this.data;
    };
    DriversService.prototype.getRandomInt = function (max) {
        return Math.floor(Math.random() * Math.floor(max));
    };
    DriversService.prototype.getRandomDriver = function () {
        var index = this.getRandomInt(this.data.length);
        return this.data[index];
    };
    return DriversService;
}());
export { DriversService };
